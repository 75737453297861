import React from 'react'

import smallBusinessImg from '../../assets/MS-Small-Business-Specialist.png';
import msCertifiedImage from '../../assets/MS-Certified-Systems-Engineer.jpg';

import './AboutUs.scss';

export default function AboutUs() {
	return (
		<div className='about-us-page-container'>

			<div>
				<h2>About Worksmart Computer Services, Inc.</h2>
				<p>
					Worksmart Computer Services, Inc., is a company
					specializing in information technology support
					and consulting.
				</p>
				<p>
					Established in 2003, Worksmart Computer Services
					Inc., has served, and continues to serve small and
					medium sized businesses in a variety of areas including:
				</p>
				<ul className='provided-services'>
					{ servicesData.map(service =>
						<li key={ service }>{ service }</li>
					) }
				</ul>
			</div>

			<div className='president-container'>
				<h3>David Coultis, President, is a Microsoft<br /> Certified Systems Engineer (MCSE)</h3>
				<p className='president-description'>
					We are dedicated to the service we provide, and
					spend a great deal of time researching options,
					studying new systems, and designing various solutions
					for our clients.
				</p>
			</div>

			<img
				className='small-business-specialist-image'
				src={ smallBusinessImg }
				alt="Microsoft Small Busniess Specialist"
			/>

			<img
				className='ms-certified-image'
				src={ msCertifiedImage }
				alt="Microsoft Certified Systems Engineer"
			/>

		</div>
	);
}

const servicesData = [
	"Associations ",
	"Accounting ",
	"Commercial Real-estate ",
	"Construction ",
	"Financial services ",
	"Public Transportation ",
	"Retail ",
	"Landscaping ",
	"Law ",
	"Restaurants ",
	"Retail ",
	"Retirement and Nursing homes ",
	"Manufacturing ",
	"and many others ",
];
