import React from 'react'
import { Link } from 'react-router-dom';

import './PageNotFound.scss';

export default function PageNotFound() {
	return (
		<div className='page-not-found-container'>
			<h2>Page not found!</h2>
			<p><Link to='/home'>Click here</Link> to return to home...</p>
		</div>
	);
};