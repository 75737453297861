import React from 'react'

import office365Image from '../../assets/Microsoft-365.png';

import './Services.scss';

export default function Services() {

	return (
		<div id='services-offered-container'>

			<h2>Services offered</h2>

			<p>
				Worksmart offers a variety of services specially designed to fit the
				needs of small to medium size businesses. Whether your company employs
				a simple peer-to-peer network, or client server system with terminal
				services and interoffice messaging, we can help you to determine your
				needs, purchase the required components, implement the solution, and
				maintain the system throughout its life.
			</p>
			<div className='services-columns'>
				<ul>
					<li>Computer related advisory and consulting services</li>
					<li>Selection of suitable software, hardware, or services</li>
					<li>Installation and configuration of hardware and software</li>
					<li>Antivirus installation, maintenance, and virus removal</li>
					<li>E-mail installation, configuration, and maintenance</li>
					<li>Remote Access, and remote desktop</li>
					<li>Virtual Private Networking both site to site, or roaming to office</li>
					<li>Firewall / router</li>
					<li>Backup solutions</li>
					<li>Virtualization of servers</li>
					<li>Training</li>
					<li>Terminal Services</li>
				</ul>
				<div className='ms-office-offer'>
					<p>We offer an office to office or roaming Virtual Private Networking solution with no monthly fees other than your Internet Service Provider costs.<br />
					<br />
					As a Microsoft Cloud Partner, we offer Microsoft Office 365<br />
					<br />
					If you would like a 30 day trial for 25 users, click the image below.</p>

					<a href="https://portal.office.com/partner/partnersignup.aspx?type=Trial&id=7cb3d5da-cab1-434b-87d6-4ea91a835c9f&msppid=626728">
						<img
							src={ office365Image }
							alt="Microsoft Office 365"
						/>
					</a>

					<p>
						This trial invitation includes:<br />
						- 25 user licenses Office 365 Business Premium Trial<br />
						- Delegated administration
					</p>
				</div>
			</div>
		</div>
	);
}