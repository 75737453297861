import React from 'react'

import './Footer.scss'

export default function Footer() {
	return (
		<div id='footer'>
			<p><b>Worksmart Computer Services, Inc.</b></p>
			<p>785-228-3930</p>
			<p>3601 SW 29th Street, Suite 114</p>
			<p>Topeka, KS 66614</p>
		</div>
	);
};
