import React from 'react';

import './CompletedMessage.scss';

export default function CompletedMessage({ formSuccess }) {
	return (
		<div className={ `contact-us-completed-container${ formSuccess ? " visible" : "" }` }>

			<p>Message sent!</p>

		</div>
	)
};
