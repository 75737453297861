import React, { useCallback, useState } from 'react'
import { Box, Button, FormControl, TextField } from '@mui/material'
import CompletedMessage from './CompletedMessage/CompletedMessage';

import './ContactForm.scss'

export default function ContactForm() {

	const [contactFormData, setContactFormData] = useState({ name: '', email: '', message: '' });
	const [contactFormErrors, setContactFormErrors] = useState({ name: '', email: '', message: '' });
	const [formSuccess, setFormSuccess] = useState(false);

	const handleNameChange = (event) => {
		setContactFormData(prev => { return { ...prev, name: event.target.value } });
		if (contactFormErrors.name !== '') setContactFormErrors(prev => { return { ...prev, name: '' } })
	}

	const handleEmailChange = (event) => {
		setContactFormData(prev => { return { ...prev, email: event.target.value } });
		if (contactFormErrors.email !== '') setContactFormErrors(prev => { return { ...prev, email: '' } })
	}

	const handleMessageChange = (event) => {
		setContactFormData(prev => { return { ...prev, message: event.target.value } });
		if (contactFormErrors.message !== '') setContactFormErrors(prev => { return { ...prev, message: '' } })
	}

	const validateFormData = useCallback(() => {

		if (!contactFormData.email)
			setContactFormErrors(prev => { return { ...prev, email: 'Email Required' } })
		else if (!/^\S+@\S+\.\S+$/.test(contactFormData.email))
			setContactFormErrors(prev => { return { ...prev, email: 'Invalid email format' } })
		else
			setContactFormErrors(prev => { return { ...prev, email: '' } })

		if (!contactFormData.name)
			setContactFormErrors(prev => { return { ...prev, name: 'Name Required' } })
		else
			setContactFormErrors(prev => { return { ...prev, name: '' } })

		if (!contactFormData.message)
			setContactFormErrors(prev => { return { ...prev, message: 'Message Required' } })
		else
			setContactFormErrors(prev => { return { ...prev, message: '' } })


	}, [contactFormData])

	const sendEmail = useCallback(async () => {

		validateFormData();

		if (contactFormErrors.name === '' &&
			contactFormErrors.email === '' &&
			contactFormErrors.message === '' &&
			contactFormData.name !== '' &&
			contactFormData.email !== '' &&
			contactFormData.message !== '') {

			setFormSuccess(true);

			try {
				const ipAddress = await getIP();
				const response = await fetch("https://worksmart-contact.azurewebsites.net/api/SendEmails", {
					method: "POST",
					body: JSON.stringify({
						...contactFormData,
						ip: ipAddress
					}),
					headers: { "Content-Type": "application/json" },
				});
				if (!response.ok) {
					throw new Error("Network response was not ok");
				} else {
					console.log("Message sent Successfully");
				}
			} catch (error) {
				console.error("There was an error submitting the form", error);
			}

		}

	}, [contactFormErrors, contactFormData, validateFormData])

	return (
		<div id='contact-form-container'>
			<div className='contact-form-text'>
			<h2>Contact Us</h2>
			<p>Send us a message if your business needs computer or networking support</p>
			</div>
			<Box className='form-control-container'>

			<CompletedMessage formSuccess={ formSuccess } />

			<FormControl className='form-control'>

				<TextField
					error={ !!contactFormErrors.name }
					helperText={ contactFormErrors.name }
					className="standard-basic"
					id="input-field-name"
					label="Name..."
					variant="standard"
					onChange={ handleNameChange }
				/>

				<TextField
					error={ !!contactFormErrors.email }
					helperText={ contactFormErrors.email }
					className="standard-basic"
					id="input-field-name"
					label="Email..."
					variant="standard"
					onChange={ handleEmailChange }
				/>

				<TextField
					error={ !!contactFormErrors.message }
					helperText={ contactFormErrors.message }
					placeholder="Your message..."
					onChange={ handleMessageChange }
					multiline
					rows={ 10 }
				/>

				<Button variant="contained" onClick={ () => sendEmail() }>Submit</Button>

			</FormControl>

		</Box >
		</div>
	)
};

const getIP = async () => {
	const res = await fetch("https://api.ipify.org/?format=json", {
		method: "GET"
	});
	const data = await res.json();
	return data.ip;
};