import React from 'react'
import { Link } from "react-router-dom";
import { optionData } from '../Pages/_data';
import logo from '../../src/assets/Worksmart-Logo.jpg';
import milestoneImage from '../../src/assets/20_years_resized.png';

import './Navbar.scss';

export default function Navbar({ page, setPage }) {
	return (
		<div className='navigation-contianer'>
			<div className='navigation-container__images'>
				<img className='logo' src={ logo } alt="WorkSmart Computer Services Inc." />
				<img className='award-logo' src={ milestoneImage } alt="WorkSmart Computer Services Inc." />
			</div>
			<div className='navigation'>

				<div className='navigation-options'>
					{ optionData.map(option =>
						<Option
							key={ option.name }
							page={ page }
							setPage={ setPage }
							option={ option }
						/>
					) }
					<div />
				</div>

			</div>
		</div>
	);
};

function Option({ page, setPage, option }) {

	let { name, href } = option;

	let cn = "option";
	if (page === name)
		cn = "option selected"

	const onClick = () => {
		setPage(name)
	}

	return (
		<Link
			to={ href }
			className={ cn }
			onClick={ () => onClick() }
		>
			{ name }
		</Link>
	);

}
