export const optionData = [
	{
		name: "Home",
		href: "/home",
	},
	{
		name: "About Us",
		href: "/about-us"
	},
	{
		name: "Services",
		href: "/services",
	},
	{
		name: "Contact",
		href: "/contact",
	},
]