import React from 'react'

import smallBusinessImg from '../../assets/MS-Small-Business-Specialist.png';
import grandStreamImg from '../../assets/Grand-Stream.png';
import msCertifiedImage from '../../assets/MS-Certified-Systems-Engineer.jpg';
import microsoft365Image from '../../assets/Microsoft-365.png';

import './Home.scss';

export default function Home() {
	return (
		<div className='home-page-container'>
			<div className='descriptions-container'>

				<h2>We are a Topeka, Kansas based technology solutions firm.</h2>
				<p>
					Established in 2003, we offer computer consulting, support, and 
					networking services to a select group of small and medium sized 
					business clients primarily in Topeka and the surrounding area.
				</p>

				<h2>Worksmart is not just a word or a name, but an action.</h2>
				<p>
					We strive to help our clients work smarter by providing them
					with high quality customized support and services. When possible,
					we meet our clients’ needs by leveraging sources of talent within the
					client office, and working with them to find smart solutions to computer
					problems.  As we believe that informed clients make better decisions,
					we use this process to put the control of the system back into the
					hands of our clients.
				</p>
			</div>
			<div className='image-gallery'>

				<a href="https://get.teamviewer.com/g566hja" target="_blank" rel="noreferrer">
					<img src={ smallBusinessImg } alt="Microsoft Small Business Specialist" />
				</a>

				<a href="https://rustrat.com/" target="_blank" rel="noreferrer">
					<img src={ msCertifiedImage } alt="Microsoft Certified Systems Engineer" />
				</a>

				<a href="https://portal.microsoftonline.com/partner/partnersignup.aspx?type=Trial&id=536b5036-bf7d-4c95-a74b-b4592244afad&msppid=626728" target="_blank" rel="noreferrer">
					<img src={ microsoft365Image } alt="Microsoft 365" />
				</a>

				<img src={ grandStreamImg } alt="Grand Stream Certified Reseller" />

			</div>
		</div>
	);
};
