import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from './Navbar/Navbar';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/AboutUs/AboutUs';
import Services from './Pages/Services/Services';
import ContactForm from './Pages/ContactForm/ContactForm';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import Footer from './Footer/Footer';

import './App.scss';

export default function App() {

	const [page, setPage] = useState(getSubFolderUrl());

	return (
		<div id="worksmart-computer-services">
			<BrowserRouter>
				<Navbar page={ page } setPage={ setPage } />
				<Routes>
					<Route path="/" element={ <Home /> } />
					<Route path="/home" element={ <Home /> } />
					<Route path="/about-us" element={ <AboutUs /> } />
					<Route path="/services" element={ <Services /> } />
					<Route path="/contact" element={ <ContactForm /> } />
					<Route path="*" element={ <PageNotFound /> } />
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
};

function getSubFolderUrl() {

	const webpageUrl = window.location.href;

	switch (true) {
		case webpageUrl.includes("/home"):
			return ("Home");

		case webpageUrl.includes("/about-us"):
			return ("About Us");

		case webpageUrl.includes("/services"):
			return ("Services");

		case webpageUrl.includes("/contact"):
			return ("Contact");

		default:
			return ("Home");
	}

}